export const URL = {
  DETAIL_INVOICE: "/invoices/:id",
  UPDATE_INVOICE: "/invoices/:id",
  LOOKUP_INFO: "/invoices/:id/lookup",
  REREAD_INFO: "/invoices/:id/reread",
  GOV_AUTHENTICATE: "/invoices/gov_authenticate",
  DELETE_INVOICE: "/invoices/:id",
  DOWNLOAD_INVOICE: "/invoices/:id/download",
  ATTACHMENT: "invoices/:id/attachment",
  LABEL: "invoices/:id/label"
};
