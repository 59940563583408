<!-- button group -->
<div class="animation-button">
  <div class="flex justify-between items-center itax-button">
    <div class="flex">
    <router-link :to="{name: 'invoices'}" class="mr-3">
      <el-button v-if="isEditInvoice" type="info" size="mini" icon="el-icon-arrow-left">
        Quay lại
      </el-button>
    </router-link>
    <div class="w-1/2 flex-grow">
      <span
        v-for="label in checkedLabel"
        :style="`background: ${label.color}`"
        class="h-8 inline-block mr-1 px-3 text-center rounded"
      >
        <span class="h-full inline-block rounded text-white truncate labelPreview">{{ label.name }}</span>
      </span>
    </div>
    </div>

    <div class="flex">
      <el-popover :visible="showLabel" placement="bottom" :width="400">
        <div v-loading="isLabelLoading">
          <div class="flex justify-between items-center px-2 label-invoice"
            v-for="(label, index) in labels"
            :key="index"
            v-if="labels.length > 0"
          >
            <div class="flex items-center">
              <el-checkbox v-model="label.checked" @change="attachLabel(label)"/>
              <el-color-picker v-model="label.color" :predefine="predefineColors" size="mini" class="mx-2 pointer-events-none"/>
              <div class="inline-block labelPreview truncate">{{ label.name }}</div>
            </div>
            <div class="cursor-pointer">
              <span class="el-icon-close" @click="removeLabel(label.id)"/>
            </div>
          </div>
          <div v-else class="text-center font-bold text-sm">Dữ liệu trống</div>

          <!-- Add items -->
          <div class="flex justify-between items-center mt-3 p-2 border-dashed border-blue-500 border" v-if="isNewLabelEditing">
            <div class="flex items-center">
              <!-- <el-checkbox v-model="color"/> -->
              <el-color-picker v-model="newLabel.color" :predefine="predefineColors" size="mini" class="mr-2"/>
              <el-input v-model="newLabel.name" />
            </div>
            <div>
              <span class="el-icon-close" @click="isNewLabelEditing=false"/>
              <span class="el-icon-check" @click="storeNewLabel"/>
            </div>
          </div>

        </div>
        <hr class="my-2">
        <div class="flex justify-between">
          <el-button size="mini" @click="isNewLabelEditing=true">Thêm nhãn</el-button>
          <div >
            <el-button size="mini" @click="showLabel=false">Đóng</el-button>
          </div>
        </div>
        <template #reference>
          <el-button type="info" size="mini" icon="el-icon-plus" @click="showLabel = true" class="w-20 h-8 mr-2">
            Nhãn
          </el-button>
        </template>
      </el-popover>

      <el-popover :visible="showNote" placement="bottom" :width="300">
        <el-input type="textarea" v-model="detailInvoice.note" rows="5" class="break-normal"></el-input>
        <div class="text-right mt-2">
          <el-button size="mini" @click="showNote=false">Hủy</el-button>
          <el-button size="mini" type="primary" @click="saveInvoiceNote">Cập nhật</el-button>
        </div>
        <template #reference>
          <el-badge :is-dot="!!detailInvoice.note" class="mx-2">
            <el-button @click="showNote = true" icon="el-icon-notebook-1" size="mini" type="info">Ghi chú</el-button>
          </el-badge>
        </template>
      </el-popover>

      <el-button
        icon="el-icon-edit"
        size="mini"
        type="primary"
        :disabled="detailInvoice.is_report"
        v-if="isEditInvoice"
        @click="editInvoice()"
      >
        Chỉnh sửa
      </el-button>
      <el-button
        size="mini"
        type="info"
        v-if="!isEditInvoice"
        @click="cancelInvoice()"
      >
        Hủy
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-if="!isEditInvoice"
        @click="submitInvoice()"
      >
        Lưu
      </el-button>
      <el-button
        icon="el-icon-delete"
        size="mini"
        type="danger"
        :disabled="detailInvoice.is_report"
        v-if="isEditInvoice"
        @click="deleteInvoice()"
      >
        Xóa
      </el-button>
    </div>
  </div>
</div>

<div class="grid grid-cols-2 lg:grid-cols-5 gap-2 mt-2" style="height: 80vh">
  <div class="h-screen col-span-5 lg:col-span-2 relative" style="height: 100%">
    <div v-if="detailInvoice.pdf_url" class="w-full h-full">
      <el-button
        v-if="!detailInvoice.message"
        @click="handleDownloadXML(detailInvoice)"
        class="absolute lg:top-16 top-2 left-2 z-10 mini"
        :type="detailInvoice.token_gov && detailInvoice.token_gov.is_authenticating ? 'warning' : 'primary'"
        size="mini"
        :icon="detailInvoice.token_gov && detailInvoice.token_gov.is_authenticating ? 'el-icon-loading' : 'el-icon-download' "
        :disabled="detailInvoice.token_gov && detailInvoice.token_gov.is_authenticating || isRequesting"
      >{{ detailInvoice.token_gov && detailInvoice.token_gov.is_authenticating ? 'Hệ thống đang làm mới phiên...' : 'Tải hóa đơn' }}</el-button >
      <el-button v-else class="absolute lg:top-16 top-2 left-2 z-10 mini" type="danger" size="mini" icon="el-icon-info" disabled>Tải hóa đơn - chọn <b>"Kết nối cơ quan thuế"</b> để xem hướng dẫn xử lý</el-button>
      <embed :src="pdfURL" class="w-full h-full">
    </div>
    <HtmlViewer v-else :invoice="detailInvoice" @onDownloadXML="handleDownloadXML(detailInvoice)" :isRequesting="isRequesting"/>
  </div>
  <div
    style="height: 100%"
    class="InforInvoices col-span-5 lg:col-span-3 relative"
  >
    <Form
      class="relative"
      as="el-form"
      :validation-schema="!isEditInvoice ? formDetailInvoice : formNull"
      @submit="saveInvoice()"
      ref="resetValidateForm"
      height="100%"
    >
      <el-scrollbar class="p-4 height-table">
        <div class="col-span-3">
          <div>
            <div class="title px-2 py-1 rounded font-bold">
              <span class="el-icon-sold-out"></span>
              ĐƠN VỊ BÁN HÀNG
            </div>
            <div class="table w-full">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td class="w-28">Đơn vị:</td>
                    <td class="font-bold">
                      <InputWithValidation
                        size="mini"
                        :invoiceStyle="isEditInvoice"
                        label="Đơn vị"
                        v-model="detailInvoice.seller_name"
                        name="seller_name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mã số thuế:</td>
                    <td class="font-bold relative">
                      <div
                        :class="!isTaxCode && !isEditInvoice ? 'is-error-tax-code': ''"
                      >
                        <InputWithValidation
                          size="mini"
                          :invoiceStyle="isEditInvoice"
                          label="Mã số thuế"
                          v-model="detailInvoice.seller_tax_code"
                          name="seller_tax_code"
                          :isNoSpace="true"
                        />
                        <span
                          v-if="!isTaxCode && !isEditInvoice"
                          class="error-tax-code"
                        >
                          Mã số thuế người bán không thuộc hóa đơn
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Địa chỉ:</td>
                    <td class="font-bold">
                      <InputWithValidation
                        size="mini"
                        :invoiceStyle="isEditInvoice"
                        label="Địa chỉ người bán"
                        v-model="detailInvoice.seller_address"
                        name="seller_address"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="mt-3">
            <div class="title px-2 py-1 rounded font-bold"><span class="el-icon-shopping-cart-full"></span> ĐƠN VỊ MUA HÀNG</div>
            <div class="table w-full">
              <table class="w-full">
                <tbody>
                  <tr>
                    <td class="w-28">Đơn vị:</td>
                    <td class="font-bold">
                      <InputWithValidation
                        size="mini"
                        :invoiceStyle="isEditInvoice"
                        label="Đơn vị mua"
                        v-model="detailInvoice.buyer_name"
                        name="buyer_name"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Mã số thuế:</td>
                    <td class="font-bold relative">
                      <div
                        :class="!isTaxCode && !isEditInvoice ? 'is-error-tax-code': ''"
                      >
                        <InputWithValidation
                          size="mini"
                          :invoiceStyle="isEditInvoice"
                          label="Mã số thuế"
                          v-model="detailInvoice.buyer_tax_code"
                          type="text"
                          name="buyer_tax_code"
                          :isNoSpace="true"
                        />
                        <span
                          v-if="!isTaxCode && !isEditInvoice"
                          class="error-tax-code"
                        >
                          Mã số thuế người mua không thuộc hóa đơn
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Địa chỉ:</td>
                    <td class="font-bold">
                      <InputWithValidation
                        size="mini"
                        :invoiceStyle="isEditInvoice"
                        label="Địa chỉ"
                        v-model="detailInvoice.buyer_address"
                        name="buyer_address"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

            <div class="mt-3">
              <div class="grid grid-cols-5 xl:grid-cols-3 gap-4 inforCheck">
                <div class="col-span-5 md:col-span-2 xl:col-span-1">
                  <div class="title px-2 py-1 rounded font-bold thongtinhoadon" >
                    <span class="el-icon-wallet"></span>
                    THÔNG TIN HÓA ĐƠN
                  </div>
                  <div class="conten">
                    <table>
                      <tbody>
                        <tr>
                          <td class="w-28">Mẫu số:</td>
                          <td class="font-bold">
                            <InputWithValidation
                              size="mini"
                              :invoiceStyle="isEditInvoice"
                              label="Mẫu số"
                              v-model="detailInvoice.form"
                              name="form"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Ký hiệu:</td>
                          <td class="font-bold">
                            <InputWithValidation
                              size="mini"
                              :invoiceStyle="isEditInvoice"
                              label="Ký hiệu"
                              v-model="detailInvoice.serial"
                              name="serial"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Số hóa đơn:</td>
                          <td class="font-bold">
                            <InputWithValidation
                              size="mini"
                              :invoiceStyle="isEditInvoice"
                              label="Số hóa đơn"
                              v-model="detailInvoice.number"
                              name="number"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Ngày PH:</td>
                          <td class="font-bold">
                            <InputWithValidation
                              size="mini"
                              :invoiceStyle="isEditInvoice"
                              label="Ngày phát hành"
                              v-model="detailInvoice.issued_date"
                              type="date"
                              name="issued_date"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Kỳ kê khai:</td>
                          <td>
                            <el-select
                              size="mini"
                              v-model="detailInvoice.report_period"
                              placeholder="Select"
                              :disabled="isEditInvoice"
                            >
                              <el-option
                                v-for="(item, index) in detailInvoice.periods"
                                :key="index"
                                :value="item"
                              >
                                {{item}}
                              </el-option>
                            </el-select>
                          </td>
                        </tr>
                        <tr>
                          <td>Loại tiền tệ:</td>
                          <td class="font-normal date-color">
                            <InputWithValidation
                              size="mini"
                              :invoiceStyle="isEditInvoice"
                              label="Loại tiền tệ"
                              v-model="detailInvoice.currency"
                              type="text"
                              name="currency"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cập nhật lúc:</td>
                          <td class="font-normal date-color like-input">
                            {{ formatDate(detailInvoice.updated_at) }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  class="col-span-5 md:col-span-3 xl:col-span-2 md:mt-0 mt-5"
                >
                  <div class="info">
                    <div>
                      <div class="title px-2 py-1 rounded font-bold">
                      <span class="el-icon-money"></span>
                        THÔNG TIN THANH TOÁN
                      </div>
                      <div class="table w-full">
                        <table class="w-full">
                          <tbody>
                            <tr>
                              <td>Hình thức thanh toán:</td>
                              <td class="style-font-bold input-text-right">
                                <InputWithValidation
                                  size="mini"
                                  :invoiceStyle="isEditInvoice"
                                  label="Hình thức thanh toán"
                                  v-model="detailInvoice.payment_method"
                                  name="payment_method"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td class="w-40">Tổng tiền trước thuế:</td>
                              <td class="style-font-bold">
                                <input-money
                                  v-model.lazy="detailInvoice.amount"
                                  class="money"
                                  :invoiceStyle="isEditInvoice"
                                  @inputChange="detailInvoice.amount = $event"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tổng tiền GTGT:</td>
                              <td class="style-font-bold">
                                <input-money
                                  v-model="detailInvoice.vat_amount"
                                  class="money"
                                  :invoiceStyle="isEditInvoice"
                                  @inputChange="detailInvoice.vat_amount = $event"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Tổng tiền thanh toán:</td>
                              <td class="font-bold">
                                <input-money
                                  v-model="detailInvoice.amount_included_vat"
                                  class="money"
                                  :invoiceStyle="isEditInvoice"
                                  @inputChange="detailInvoice.amount_included_vat = $event"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Số tiền bằng chữ:</td>
                              <td class="money-text input-text-right">
                                <InputWithValidation
                                  size="mini"
                                  :invoiceStyle="isEditInvoice"
                                  label="Số tiền bằng chữ"
                                  v-model="detailInvoice.amount_in_words"
                                  name="moneyText"
                                />
                              </td>
                            </tr>

                            <tr v-if="detailInvoice.currency != 'VND' && detailInvoice.currency != 'VNĐ'">
                              <td>Tỷ giá:</td>
                              <td class="money-text input-text-right">
                                <input-money
                                  v-model="detailInvoice.ti_gia"
                                  class="money"
                                  :invoiceStyle="isEditInvoice"
                                  @inputChange="detailInvoice.ti_gia = $event"
                                />
                              </td>
                            </tr>

                            <tr v-if="detailInvoice.currency != 'VND' && detailInvoice.currency != 'VNĐ'">
                              <td>Quy đổi VND:</td>
                              <td class="money-text input-text-right like-input">
                                <p class="font-bold text-right">{{ formatNumber(Math.round(detailInvoice.amount_included_vat * detailInvoice.ti_gia)) }}</p>
                              </td>
                            </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          <div
            class="
              mt-3
              title
              px-2
              flex
              justify-between
              items-center
              py-1
              rounded
              font-bold
            "
          >
            <div>
            <span class="el-icon-office-building"></span>
            KIỂM TRA VỚI HỆ THÔNG TỔNG CỤC THUẾ
            </div>
            <loading-invoice
              v-if="isUpdateLookupInfo"
              textContent="Hệ thống đang kiểm tra..."
            />
          </div>

          <div class="info mt-1 relative" v-if="detailInvoice.seller.status != undefined">
            <div
              class="
                title-child
                font-bold
                block
              "
            >
              <p>Thông tin người bán hàng hóa, dịch vụ</p>
              <div class="absolute itax-button addProduct right-0 top-2">
                <el-button
                  size="mini"
                  class="rounded hover:text-primary p-1"
                  @click="refeshLookup()"
                  :disabled="isUpdateLookupInfo"
                >
                  Kiểm tra lại
                </el-button>
              </div>
            </div>
            <div v-if="detailInvoice.seller.status">
              <ul>
                <li class="flex items-center gap-1">
                  <i v-if="!isRed(detailInvoice.seller.status)" class="el-icon-success text-green-600" />
                  <i v-else class="el-icon-warning text-red-600" />
                  <span>{{ detailInvoice.seller.status }}</span>
                </li>

                <li class="flex items-center" v-if="detailInvoice.seller.ngayDongMST">
                  <div class="w-4 h-4"></div>
                  <div class="ml-1">Ngày đóng mã số thuế: <b>{{ formatDate(detailInvoice.seller.ngayDongMST) }}</b></div>
                </li>

                <li class="flex">
                  <div class="w-4 h-4"></div>
                  <el-collapse v-model="activeNames" class="ml-1">
                    <el-collapse-item title="Lịch sử" name="1">
                      <template #title>
                        Ngày thay đổi thông tin gần nhất: <b v-if="detailInvoice.seller.gov_updated_at" class="ml-1">{{ formatDate(detailInvoice.seller.gov_updated_at) }}</b>
                      </template>
                      <div v-for="item in detailInvoice.seller_status_history" class="flex items-center gap-1">
                        <i v-if="!isRed(item.status)" class="el-icon-success text-green-600" />
                        <i v-else class="el-icon-warning text-red-600" />
                        <div>{{ item.status }} - <b> {{ formatDate(item.gov_updated_at) }}</b> </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </li>

              </ul>
            </div>
            <p v-else class="flex items-center gap-1">
              <i class="el-icon-question text-yellow-600" />
              Chưa có thông tin
            </p>

            <div class="mt-3">
              <div class="title-child font-bold flex items-center">
                Thông tin hóa đơn
              </div>
              <p v-if="lookupinfo && lookupinfo.invoice_status" class="flex items-center">
              <ul>
                <li class="flex gap-1" v-if="lookupinfo.invoice_status">
                  <i v-if="lookupinfo.invoice_status == 1" class="el-icon-success text-green-600" />
                  <i v-else class="el-icon-warning text-red-600" />
                  <p v-html="lookupinfo.invoice_note"></p>
                </li>

                <li class="flex items-center gap-1" v-else>
                  <i class="el-icon-question text-yellow-600" />
                  Chưa có thông tin
                </li>
                <li class="flex items-center">
                  <div class="w-4 h-4"></div>
                  <div v-if="lookupinfo.updated_at" class="ml-1">
                    Kết quả tra cứu ngày:
                    <b>
                      {{formatDate(lookupinfo.updated_at)}}
                      {{formatTime(lookupinfo.updated_at)}}
                    </b>
                  </div>

                </li>
              </ul>

              </p>
              <p v-else class="flex items-center gap-1">
                <i class="el-icon-question text-yellow-600" />
                Chưa có thông tin
              </p>
            </div>

            <hr class="my-2">

            <div class="flex">
              <div class="w-1/2">
                <div class="title-child font-bold flex items-center gap-1">
                  <el-tooltip class="item" effect="dark" content="Thông tin đang được cập nhật" placement="top" v-if="detailInvoice.lookupinfo_other.buyer_is_updating">
                    <i class="el-icon-loading ml-2"/>
                  </el-tooltip>
                  <i v-if="detailInvoice.lookupinfo_other.buyer_address__check && detailInvoice.lookupinfo_other.buyer_name__check" class="el-icon-success text-green-600" />
                  <i v-else class="el-icon-warning text-red-600" />
                  Thông tin người mua
                </div>
                <ul class="ml-2" v-if="!detailInvoice.lookupinfo_other.buyer_is_updating">
                  <el-tooltip class="item" effect="dark" :content="detailInvoice.lookupinfo_other.buyer_name" placement="top">
                    <li :class="{'text-red-600': !detailInvoice.lookupinfo_other.buyer_name__check}">
                      - {{ detailInvoice.lookupinfo_other.buyer_name__check ? 'Đúng' : 'Sai' }} tên người mua
                    </li>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="detailInvoice.lookupinfo_other.buyer_address" placement="bottom">
                    <li :class="{'text-red-600': !detailInvoice.lookupinfo_other.buyer_address__check}">
                      - {{ detailInvoice.lookupinfo_other.buyer_address__check ? 'Đúng' : 'Sai' }} địa chỉ người mua
                    </li>
                  </el-tooltip>
                </ul>
              </div>

              <div class="">
                <div class="title-child font-bold flex items-center gap-1">
                  <el-tooltip class="item" effect="dark" content="Thông tin đang được cập nhật" placement="top" v-if="detailInvoice.lookupinfo_other.seller_is_updating">
                    <i class="el-icon-loading ml-2"/>
                  </el-tooltip>
                  <i v-if="detailInvoice.lookupinfo_other.seller_address__check && detailInvoice.lookupinfo_other.seller_name__check" class="el-icon-success text-green-600" />
                  <i v-else class="el-icon-warning text-red-600" />
                  Thông tin người bán
                </div>
                <ul class="ml-2" v-if="!detailInvoice.lookupinfo_other.seller_is_updating">
                  <el-tooltip class="item" effect="dark" :content="detailInvoice.lookupinfo_other.seller_name" placement="top">
                    <li :class="{'text-red-600': !detailInvoice.lookupinfo_other.seller_name__check}">
                      - {{ detailInvoice.lookupinfo_other.seller_name__check ? 'Đúng' : 'Sai' }} tên người bán
                    </li>
                  </el-tooltip>
                  <el-tooltip class="item" effect="dark" :content="detailInvoice.lookupinfo_other.seller_address" placement="bottom">
                    <li :class="{'text-red-600': !detailInvoice.lookupinfo_other.seller_address__check}">
                        - {{ detailInvoice.lookupinfo_other.seller_address__check ? 'Đúng' : 'Sai' }} địa chỉ người bán
                    </li>
                  </el-tooltip>
                </ul>
              </div>
            </div>

          </div>

        </div>

        <!-- Attachments -->
        <div class="mt-2">
          <div class="title px-2 py-1 rounded font-bold">
            <span class="el-icon-folder-opened"></span>
             TỆP ĐÍNH KÈM
          </div>
            
          <small class="small-text">*Tệp hỗ trợ tôi đa 5MB. Kéo thả hoặc chọn để tải tệp</small>
          <div class="mb-5 w-full">
            <el-upload
              class="avatar-uploader w-full"
              drag
              action="#"
              :file-list="fileList"
              :on-change="handleFilesSuccess"
              :on-remove="handleFilesRemove"
              :before-upload="beforeAvatarUpload"
              multiple
            >
              <template #default>
                <i class="el-icon-plus avatar-uploader-icon"></i>
              </template>
              <template #file="{file}">
                <a class="el-upload-list__item-name" target="_blank" :href="file.url">
                  <i class="el-icon-document"></i>{{ file.name }}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-circle-check"></i>
                </label>
                <i class="el-icon-close" @click="handleFilesRemove(file)"></i>
              </template>
            </el-upload>
          </div>
        </div>

        <div class="detail-invoice h-full mt-3">
          <div class="title px-2 py-1 rounded font-bold">
            <span class="el-icon-s-grid"></span>
            CHI TIẾT HÓA ĐƠN
          </div>
          <el-scrollbar class="tableProduct py-2 w-full">
            <table class="w-full text-right minWidthlg">
              <thead>
                <tr>
                  <th class="text-center">STT</th>
                  <th class="text-left nameProduct">Tên hàng hóa, dịch vụ</th>
                  <th class="text-left w-16">ĐVT</th>
                  <th class="w-10 text-center">SL</th>
                  <th class="w-24">ĐG</th>
                  <th class="w-20" v-if="detailInvoice.form != 2">TS</th>
                  <th class="moneyVat">Tiền trước thuế</th>
                  <th class="moneyVat">Tiền thuế GTGT</th>
                  <th>Tổng tiền</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-for="(product, index) in invoicesFilter" :key="index">
                <tr v-if="!product.delete">
                  <td class="text-center">{{ 1 + index }}</td>
                  <td
                    class="text-left textLeft"
                    :class="{isEditInvoice:isEditInvoice}"
                  >
                    <input
                      :readonly="isEditInvoice"
                      class="inputProduct textLeft"
                      v-model="product.description"
                      placeholder="Tên hàng hóa"
                    />
                  </td>
                  <td
                    class="text-left textLeft"
                    :class="{isEditInvoice:isEditInvoice}"
                  >
                    <input
                      :readonly="isEditInvoice"
                      class="inputProduct textLeft"
                      v-model="product.unit"
                      placeholder="ĐVT"
                    />
                  </td>
                  <td :class="{'text-center': true, isEditInvoice:isEditInvoice}">
                    <input
                      :readonly="isEditInvoice"
                      class="inputProduct"
                      v-model="product.quantity"
                      placeholder="SL"
                    />
                  </td>
                  <td :class="{isEditInvoice:isEditInvoice}">
                    <input-money
                      v-model="product.unit_price"
                      class="money inputProduct"
                      :invoiceStyle="isEditInvoice"
                      :decima="0"
                      @inputChange="product.unit_price = $event"
                    />
                  </td>
                  <td :class="{isEditInvoice:isEditInvoice}" v-if="detailInvoice.form != 2">
                    <el-select
                      class="w-full select-vat"
                      v-model="product.vat"
                      placeholder="vat"
                      :disabled="isEditInvoice"
                    >
                      <el-option value="KKKNT">KKKNT</el-option>
                      <el-option value="KCT">KCT</el-option>
                      <el-option value="0%">0% </el-option>
                      <el-option value="5%">5% </el-option>
                      <el-option value="7%">7% </el-option>
                      <el-option value="8%">8% </el-option>
                      <el-option value="10%">10% </el-option>
                    </el-select>
                  </td>
                  <td :class="{isEditInvoice:isEditInvoice}" class="minWMoney">
                    <input-money
                      v-model="product.amount"
                      class="money inputProduct"
                      :invoiceStyle="isEditInvoice"
                      :decima="0"
                      @inputChange="product.amount = $event"
                    />
                  </td>
                  <td :class="{isEditInvoice:isEditInvoice}" class="minWMoney">
                    <input-money
                      v-model="product.vat_amount"
                      class="money inputProduct"
                      :invoiceStyle="isEditInvoice"
                      :decima="0"
                      @inputChange="product.vat_amount = $event"
                    />
                  </td>
                  <td :class="{isEditInvoice:isEditInvoice}" class="minWMoney">
                    <input-money
                      v-model="product.amount_included_vat"
                      class="money inputProduct"
                      :invoiceStyle="isEditInvoice"
                      :decima="0"
                      @inputChange="product.amount_included_vat = $event"
                    />
                  </td>
                  <td class="w-8">
                    <img
                      v-if="!isEditInvoice"
                      src="@/assets/icons/times.svg"
                      alt=""
                      class="ml-1 w-3 h-3 cursor-pointer deleteRow"
                      @click="deleteOneRow(product)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            </el-scrollbar>
          <!-- </div> -->
          <div
            class="mt-2 text-center addProduct itax-button"
            v-if="!isEditInvoice"
          >
            <el-button
              size="mini"
              class="rounded hover:text-primary"
              @click="addOneRow()"
            >
              <div class="flex items-center">
                <img
                  src="@/assets/icons/icon_add.svg"
                  alt=""
                  class="w-4 h-4 mr-1"
                />
                <span>Thêm</span>
              </div>
            </el-button>
          </div>
        </div>
      </el-scrollbar>
      <button class="hidden" id="form-submit" type="submit"></button>
    </Form>
  </div>
</div>

<el-dialog :width="dialogWidth" v-model="showAddModalCapture" top="5vh">
  <div class="block">
    <img ref="captureUrl" class="mx-auto object-contain" />
  </div>
  <div class="mt-3 text-right itax-button">
    <span class="dialog-footer">
      <el-button size="mini" @click="showAddModalCapture = false">
        Đóng
      </el-button>
    </span>
  </div>
</el-dialog>
