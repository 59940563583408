<div class="relative overflow-hidden">
  <div class="command">
    <i class="el-icon-zoom-in absolute left-2 top-16 z-10 text-lg cursor-pointer" @click="zoom(1)"></i>
    <i class="el-icon-zoom-out absolute left-2 top-24 z-10 cursor-pointer" @click="zoom(-1)"></i>
  </div>
  <el-button
    v-if="!invoice.message"
    @click="handleClickXML"
    class="absolute top-2 left-2 z-10 mini"
    :type="invoice.token_gov && invoice.token_gov.is_authenticating ? 'warning' : 'primary'"
    size="mini"
    :icon="invoice.token_gov && invoice.token_gov.is_authenticating ? 'el-icon-loading' : 'el-icon-download' "
    :disabled="invoice.token_gov && invoice.token_gov.is_authenticating || isRequesting"
  >{{ invoice.token_gov && invoice.token_gov.is_authenticating ? 'Hệ thống đang làm mới phiên...' : 'Tải hóa đơn' }}</el-button >
  <el-button v-else class="absolute top-2 left-2 z-10 mini" type="danger" size="mini" icon="el-icon-info" disabled>Tải hóa đơn - chọn <b>"Kết nối cơ quan thuế"</b> để xem hướng dẫn xử lý</el-button>
  <div class="max-h-no-overflow">
    <div
      class="container mx-auto sm:px-4 html-container w-full h-full"
      id="html-viewer"
    >
      <div class="flex flex-wrap justify-end">
        <div class="flex flex-col">
          <div class="leading-normal form-control-label display-text">
            Mẫu số: {{ invoice.form }}
          </div>
          <div class="leading-normal form-control-label display-text">
            Ký hiệu: {{ invoice.serial }}
          </div>
          <div class="leading-normal form-control-label">
            Số: <span class="invoice-number"> {{ invoice.number }} </span>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full">
          <h3 class="text-center invoice-word display-text mt-3">
            {{ getInvoiceTitle(invoice.form) }}
          </h3>
          <h6 class="text-center invoice-definition display-text">
            Bảng thể hiện của hoá đơn điện tử
          </h6>
        </div>
      </div>
      <div class="w-full text-center display-text">
        Ngày {{ new Date(invoice.issued_date).getDate() }} tháng {{ new
        Date(invoice.issued_date).getMonth() + 1 }} năm {{ new
        Date(invoice.issued_date).getFullYear() }}
      </div>
      <div class="w-full text-center display-text">
        MCCQT: {{ invoice.cqt }}
      </div>
      <hr class="my-3" />
      <div class="">
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Tên người bán: {{ invoice.seller_name }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Mã số thuế: {{ invoice.seller_tax_code }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Địa chỉ: {{ invoice.seller_address }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Số điện thoại: {{ invoice.seller_phone }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Số tài khoản: {{ invoice.seller_bank_account }} - tại ngân hàng:
              {{ invoice.seller_bank_name }}
            </label>
          </div>
        </div>
      </div>
      <hr class="my-3" />
      <div class="">
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Tên người mua: {{ invoice.buyer_name }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Mã số thuế: {{ invoice.buyer_tax_code }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Địa chỉ: {{ invoice.buyer_address }}
            </label>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Hình thức thanh toán: {{ invoice.payment_method }}
            </label>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="invoice-group">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Đồng tiền thanh toán: {{ invoice.currency }}
            </label>
          </div>
          <div class="invoice-group" v-if="invoice.currency && invoice.currency != 'VND' && invoice.currency != 'VNĐ'">
            <label
              class="
                w-full
                pt-2
                pb-2
                mb-0
                leading-normal
                form-control-label
                display-text
              "
            >
              Tỷ giá: {{ formatNumber(invoice.ti_gia) }}
            </label>
          </div>
        </div>
      </div>
      <div id="tblProduct" class="mt-3 w-50">
        <table class="product w-full max-w-full mb-4 bg-transparent">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="30px"
              >
                STT
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="80px"
              >
                Tính chất
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="90px"
              >
                Tên hàng hóa, dịch vụ
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="50px"
              >
                Đơn vị tính
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="40px"
              >
                Số lượng
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="100px"
              >
                Đơn giá
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="50px"
                v-if="invoice.form != 2"
              >
                Thuế suất
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="100px"
              >
                Thành tiền chưa có thuế GTGT
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="100px"
              >
                Tiền thuế GTGT
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="100px"
              >
                Thành tiền có thuế GTGT
              </th>
              <th
                scope="col"
                class="text-center align-center display-text"
                width="100px"
                v-if="invoice.thu_ho"
              >
                {{invoice.thu_ho}}
              </th>
            </tr>
            <tr>
              <td scope="col" class="text-center display-text">1</td>
              <td scope="col" class="text-center display-text">2</td>
              <td scope="col" class="text-center display-text">3</td>
              <td scope="col" class="text-center display-text">4</td>
              <td scope="col" class="text-center display-text">5</td>
              <td scope="col" class="text-center display-text">6</td>
              <td scope="col" class="text-center display-text" v-if="invoice.form != 2">7</td>
              <td scope="col" class="text-center display-text">8=5x6</td>
              <td scope="col" class="text-center display-text">9=8x7</td>
              <td scope="col" class="text-center display-text">10=9+8</td>
              <td scope="col" class="text-center display-text" v-if="invoice.thu_ho">11</td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(product, index) in invoice.products"
              :key="index"
              :value="item"
            >
              <td scope="row" class="text-center display-text">
                {{ index + 1 }}
              </td>
              <td class="display-text">{{ product.tchat }}</td>
              <td class="display-text">{{ product.description }}</td>
              <td class="text-center display-text">{{ product.unit }}</td>
              <td class="text-center display-text">{{ product.quantity }}</td>
              <td class="text-right display-text">
                {{ formatNumber(product.unit_price) }}
              </td>
              <td class="text-right display-text" v-if="invoice.form != 2">
                {{ product.view_only || (product.tchat && product.tchat.toLowerCase().indexOf('ghi chú') != -1) ? '' : product.vat }}
              </td>
              <td class="text-right display-text">
                {{ formatNumber(product.amount) }}
              </td>
              <td class="text-right display-text">
                {{ formatNumber(product.vat_amount) }}
              </td>
              <td class="text-right display-text">
                {{ formatNumber(product.amount_included_vat) }}
              </td>
              <td scope="col" class="text-center display-text" v-if="invoice.thu_ho && index == 0" :rowspan="invoice.products.length">{{ formatNumber(invoice.thu_ho_cost) }}</td>
            </tr>

            <tr>
              <td :colspan="invoice.form != 2 ? 9 : 8" class="display-text">
                Tổng tiền chưa có thuế <span v-if="invoice.form != 2">GTGT</span>
              </td>
              <td class="text-right display-text">
                {{ formatNumber(invoice.amount) }}
              </td>
              <td scope="col" rowspan="5" v-if="invoice.thu_ho" />
            </tr>
            <tr>
              <td :colspan="invoice.form != 2 ? 9 : 8" class="display-text">
                Tổng tiền thuế giá trị gia tăng theo từng loại thuế suất
              </td>
              <td class="text-right display-text">
                {{ formatNumber(invoice.vat_amount) }}
              </td>
            </tr>
            <tr>
              <td :colspan="invoice.form != 2 ? 9 : 8" class="display-text">
                Tổng tiền thanh toán đã có thuế <span v-if="invoice.form != 2">GTGT</span>
              </td>
              <td class="text-right display-text">
                {{ formatNumber(invoice.amount_included_vat) }}
              </td>
            </tr>
            <tr>
              <td :colspan="invoice.form != 2 ? 10 : 9" class="text-left display-text">
                Số tiền viết bằng chữ: {{ invoice.amount_in_words }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-wrap invoice-sign">
        <div class="w-1/2 text-center display-text">Người mua hàng</div>
        <div class="w-1/2 text-center display-text">Người bán hàng</div>
      </div>
      <div class="flex flex-wrap signature-group">
        <div class="w-1/2"></div>
        <div class="w-1/2">
          <div class="display-text signature">
            Ký bởi: {{ invoice.seller_name }}
          </div>
          <div class="display-text signature">
            Ký ngày: {{ new Date(invoice.issued_date).getDate() }} tháng {{ new
            Date(invoice.issued_date).getMonth() + 1 }} năm {{ new
            Date(invoice.issued_date).getFullYear() }}
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full text-center invoice-note display-text">
          (Cần kiểm tra, đối chiếu khi lập, giao, nhận hóa đơn)
        </div>
        <div class="w-full text-center invoice-note display-text">
          DVCC Hóa đơn điện tử: {{ getMsttcgpLabel(invoice.msttcgp)}} - {{ invoice.msttcgp }}
        </div>
      </div>
    </div>
  </div>
</div>
